<template>
  <v-dialog
    v-model="isPayementModalOpen"
    width="600"
    height="800"
    persistent
    content-class="dialog-background"
  >
    <v-card>
      <v-toolbar
        class="py-2"
        flat
      >
        <v-toolbar-title>
          <h2 class="font-weight-regular text-h3">Complete payement</h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mr-0"
        >
          <v-icon @click="cancel()">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div
          class="flex-grow-1 ma-4 ms-10"
          style="max-width: 300px"
        >
          <v-autocomplete
            outlined
            dense
            :label="$t('pos_n.payment_methods')"
            :items="paymentMethods"
            item-value="id"
            item-text="label"
            return-object
            v-model="paymentMethod"
          ></v-autocomplete>
        </div>
        <!-- cash payment -->
        <template v-if="paymentMethod.name === 'cash'">
          <div class="me-16">
            <div
              class="text-h5 d-flex justify-space-between ms-16 py-3"
              style="width: 500px"
            >
              <span
                ><strong>{{ $t("pos_n.ttc") }}</strong></span
              >
              <span class="mr-14"
                ><strong>{{ total.toFixed(2) }}</strong></span
              >
            </div>
            <v-divider class="ms-10"></v-divider>
            <div
              class="text-h5 d-flex justify-space-between mr-16 py-3 ms-16"
              style="width: 500px"
            >
              <span>{{ $t("pos_n.stamp_due") }}</span>
              <span class="mr-14">{{ posOrder.stamp_amount.toFixed(2) }}</span>
            </div>
            <v-divider class="ms-10"></v-divider>
            <div
              class="text-h5 d-flex justify-space-between mr-16 py-3 ms-16"
              style="width: 500px"
            >
              <span
                ><strong>{{ $t("pos_n.due_amount") }}</strong></span
              >
              <span class="mr-14"
                ><strong>{{ posOrder.total.toFixed(2) }}</strong></span
              >
            </div>
            <v-divider class="ms-10"></v-divider>
            <div
              class="text-h5 d-flex justify-space-between mr-16 py-3 ms-16"
              style="width: 500px"
            >
              <span>{{ $t("pos_n.due_change") }}</span>
              <span class="mr-14">{{ dueChange().toFixed(2) }}</span>
            </div>
            <v-divider class="ms-10"></v-divider>
          </div>
          <div class="ma-7">
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  :label="$t('amount_received')"
                  @keypress="isNumber($event)"
                  v-model="current"
                  type="number"
                  min="0"
                  @input="dueChange()"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </template>
        <!-- check payment -->
        <template v-if="paymentMethod.name === 'check'">
          <div
            class="me-16"
            style="height: 30vh"
          >
            <v-row class="mx-4">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  dense
                  hide-details
                  outlined
                  type="text"
                  :label="$t('pos_n.bank.issuer')"
                  v-model="posOrder.payment_details.issuer"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  dense
                  hide-details
                  outlined
                  :label="$t('pos_n.bank.recipient')"
                  type="text"
                  v-model="posOrder.payment_details.recipient"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-4">
              <v-col cols="12">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  :label="$t('pos_n.bank.rib')"
                  type="number"
                  v-model="posOrder.payment_details.rib"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-4">
              <v-col md="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      v-model="posOrder.payment_details.date"
                      :label="$t('pos_n.bank.date')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="pr-2"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="posOrder.payment_details.date"
                    no-title
                    color="primary lighten-1"
                    header-color="primary lighten-1"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="6">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  :label="$t('pos_n.bank.amount')"
                  type="number"
                  min="0"
                  v-model="posOrder.payment_details.check_amount"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="ms-10"></v-divider>
          </div>
        </template>
        <!-- card payment -->
        <template v-if="paymentMethod.name === 'card'">
          <div
            class="me-16"
            style="height: 30vh"
          >
            <v-row class="mx-4">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  dense
                  hide-details
                  outlined
                  type="text"
                  :label="$t('pos_n.bank.transaction')"
                  v-model="posOrder.payment_details.transaction"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-if="paymentMethod.name != 'cash'">
          <div class="me-16">
            <v-divider class="ms-10"></v-divider>
            <div
              class="text-h5 d-flex justify-space-between mr-16 py-3 ms-16"
              style="width: 500px"
            >
              <span
                ><strong>{{ $t("pos_n.due_amount") }}</strong></span
              >
              <span class="mr-14"
                ><strong>{{ posOrder.total.toFixed(2) }}</strong></span
              >
            </div>
            <v-divider class="ms-10"></v-divider>
          </div>
        </template>
      </v-card-text>
      <template>
        <div class="payment-calculator mt-1">
          <div
            v-for="n in 9"
            :key="n"
          >
            <div
              @click="append(n)"
              class="btn"
              v-ripple
              role="button"
            >
              <a class="numero">{{ n }}</a>
            </div>
          </div>

          <div
            @click="append(0)"
            class="btn"
          >
            <a class="numero">0</a>
          </div>
          <div
            class="btn"
            @click="dot()"
          >
            <a class="numero">,</a>
          </div>
          <div
            class="btn"
            @click="clearAll()"
          >
            <a class="numero">
              <v-icon class="ms-2"> $vuetify.icons.iconRemove</v-icon></a
            >
          </div>
        </div>
      </template>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="cancel()"
          color="indigo"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="primary"
          text
          @click="submitOrder"
          :disabled="!canValidate"
        >
          {{ $t("pos_n.validate_payment") }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <invoice-a7
      ref="invoiceA7"
      :order="posOrder"
    ></invoice-a7>
  </v-dialog>
</template>

<script>
import Localbase from "localbase";
import dbService from "@/store/services/localdb-service";
import { network } from "@/mixins/network";

let db = new Localbase("db");
db.config.debug = false;
export default {
  mixins: [network],

  props: {
    isPayementModalOpen: Boolean,
    togglePayementModal: Function,
    total: Number,
    order: Array,
  },
  created: function () {
    this.updateOrder();
  },
  components: {
    invoiceA7: () => import("../../../pdf/pos/invoiceA7New.vue"),
  },
  computed: {
    canValidate() {
      return this.paymentMethod.name === "cash" ? this.dueChange() >= 0 : true;
    },
  },
  watch: {
    stampDuty(newValue) {
      this.stamp = Number(newValue);
    },

    paymentMethod() {
      this.updateOrder();
    },
  },
  data() {
    return {
      current: 0,
      paymentMethods: [
        { id: 1, name: "cash", label: this.$t("pos_n.cash") },
        { id: 2, name: "card", label: this.$t("pos_n.card") },
        { id: 3, name: "check", label: this.$t("pos_n.check") },
      ],
      paymentMethod: {
        id: 1,
        name: "cash",
        label: this.$t("pos_n.cash"),
      },
      menu: false,
      stamp: 0,
      posOrder: {
        products: [],
        payment_details: {},
      },
    };
  },
  methods: {
    updateOrder() {
      this.posOrder.stamp_amount = this.calc_stamp();
      this.posOrder.reduction = 0;
      this.posOrder.amount_received = this.amount_received();
      this.posOrder.ttc = this.total;
      this.posOrder.total = this.posOrder.ttc + this.posOrder.stamp_amount;
      if (this.paymentMethod.name === "check") {
        this.posOrder.payment_details.check_amount = this.posOrder.total;
        this.posOrder.payment_details.date = new Date()
          .toISOString()
          .substr(0, 10);
        this.current = 0;
      }
    },
    calc_stamp() {
      return this.paymentMethod.name !== "cash" || this.total < 500
        ? 0
        : Math.min(10000, this.total / 100);
    },
    amount_received() {
      return this.paymentMethod.name === "cash" && this.current > 0
        ? Number(this.current)
        : 0;
    },
    close() {
      this.togglePayementModal(true);
    },
    cancel() {
      this.togglePayementModal();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        // charCode > 31 &&
        charCode < 48 ||
        charCode > 57
        // charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clearAll() {
      this.current = 0;
    },
    append(number) {
      if (this.current == 0) this.current = `${number}`;
      else this.current = `${this.current}${number}`;
    },
    dot() {
      if (this.current.indexOf(".") == -1) this.append(".");
    },

    dueChange() {
      this.posOrder.amount_received = Number(this.current);
      return this.current > 0 ? this.current - this.posOrder.total : 0;
    },
    async submitOrder() {
      try {
        if (!this.order || this.order.length == 0) {
          this.$store.dispatch("alerts/error", "Products are required");
          this.is_saving = false;
          return;
        }
        this.is_saving = true;
        this.posOrder.products = [...this.order];
        let created_at = new Date();
        this.posOrder.tracking_number_ext = "YI-P-" + created_at.valueOf();
        this.posOrder.created_at = `${created_at.getDate()}/${
          created_at.getMonth() + 1
        }/${created_at.getFullYear()} à ${created_at.getHours()}:${created_at.getMinutes()}:${created_at.getSeconds()}`;
        this.posOrder.reduction = this.posOrder.products.reduce(
          (acc, item) => acc + (item?.reduction ?? 0),
          0
        );
        setTimeout(() => {
          this.$refs.invoiceA7.generateReport();
        }, 300);

        let order = Object.assign({}, this.posOrder);
        order.products = this.posOrder.products.map((item) => {
          return {
            id: item.id,
            qte_in_order: item.qte_in_order,
            order_tva: item.tva,
            discount: item?.reduction ?? 0,
            order_price_ht: item.price_ht,
            promotion_id: item?.promotion?.id,
          };
        });
        order.payment_method = this.paymentMethod.name;
        order.created_at = created_at.toISOString();
        await this.storePos(order);
        this.$store.dispatch("alerts/success", this.$t("created_successfully"));
        setTimeout(() => {
          this.close();
        }, 1000);
        this.is_saving = false;
      } catch (error) {
        this.is_saving = false;
        this.$store.dispatch("alerts/error", error);
        this.formErrors = error.response?.data?.errors;
      }
    },
    async storePos(order) {
      if (!this.isOnline) {
        await db
          .collection("orders")
          .doc(this.posOrder.tracking_number_ext)
          .set(this.posOrder);
        return;
      }

      try {
        await this.$store.dispatch("orders/addPos", order);
      } catch (error) {
        if (error.code !== "ECONNABORTED") {
          const data = {
            failed: 1,
            error:
              error?.response?.data?.errors ?? error?.response?.data?.message,
          };
          dbService.updateOrderByTrackingNumber(
            order.tracking_number_ext,
            data
          );
          await this.$store.dispatch("orders/logErrorPos", order, {
            root: true,
          });
          dbService.markOrderAsLogged(order.tracking_number_ext);
        } else throw new Error("An error occurred");
      }
    },
  },
};
</script>
<style >
.payment-calculator {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  text-align: center;
  font-size: 30px;
  width: 350px;
  margin-left: 60px;
  margin-bottom: 30px;
}
a.numero {
  color: black;
}
.dialog-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(10px);
}
</style>
